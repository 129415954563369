export class DomHelper {
  /**
   * This is a global event listener that prevents
   *  the mouse wheel and arrow keys from affecting `input[type=number]`
   */
  static preventScrollingNumberInputs() {
    /**
     * Based on https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number
     */
    const preventScrollWheel = (event: MouseEvent) => {
      if (DomHelper.isInputElement(event.target) && event.target.type === "number") {
        event.preventDefault();
      }
    };

    /**
     * Based on https://stackoverflow.com/questions/24192333/prevent-arrow-keys-from-changing-values-in-a-number-input
     */
    const preventArrowKeys = (event: KeyboardEvent) => {
      if (DomHelper.isInputElement(event.target) && event.target.type === "number") {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      }
    };

    document.addEventListener(
      "wheel",
      preventScrollWheel,
      { passive: false } // https://stackoverflow.com/a/37721906
    );
    document.addEventListener("keydown", preventArrowKeys);
  }

  /**
   * This is a typeguard for helping recognize ONLY input elements.
   */
  static isInputElement(it: any): it is HTMLInputElement {
    return it && "type" in it;
  }

  /**
   * Converts a string to dash-style
   *   see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/dataset#name_conversion
   *
   * eg. example1String -> example1-string
   * @param string to convert
   * @returns dash-styled string
   */
  static dashStyle(inputString: string): string {
    return inputString
      .split(/(?=[A-Z])/)
      .reduce((result, word, idx) => result + (idx ? "-" : "") + word.toLowerCase(), "");
  }
}
