//////////////////////////////////////////////////////
// START: JavaScript libraries
//////////////////////////////////////////////////////

// jquery
window.$ = window.jQuery = window.jquery = require("jquery");

// bootstrap
require("bootstrap/dist/js/bootstrap.bundle");

// front end validation
require("jquery-mask-plugin/dist/jquery.mask");

//////////////////////////////////////////////////////
// START: Images
//////////////////////////////////////////////////////
require.context("../images/", true, /\.(gif|jpg|jpeg|png|svg|ico)$/i);

//////////////////////////////////////////////////////
// START: Stylesheets
//////////////////////////////////////////////////////
import "src/shared/lib/hyper/scss/application.scss";
import "src/shared/lib/greensock/gsap.min.js";
import "./tailwind.css";
import "react-loading-skeleton/dist/skeleton.css";

// Required dependencies
import * as Turbo from "@hotwired/turbo";
Turbo.session.drive = false;

// file uploads
import * as ActiveStorage from "@rails/activestorage";
import "src/shared/direct_uploads";
ActiveStorage.start();

// Plugins
require("bootstrap-select");
require("metismenu/dist/metisMenu");

// hyper configuration
require("src/shared/lib/hyper/js/hyper");

import { DomHelper } from "src/shared/helpers/dom_helper";
document.addEventListener("DOMContentLoaded", () => {
  DomHelper.preventScrollingNumberInputs();
});
