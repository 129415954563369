document.addEventListener("DOMContentLoaded", () => {
  !(($) => {
    class NumeroPlugins {
      constructor() {
        (this.$body = $("body")), (this.$window = $(window));
      }
      initInputMask() {
        $.fn.mask &&
          $('[data-toggle="input-mask"]').each((_, obj) => {
            var maskFormat = $(obj).data("maskFormat");
            var reverse = $(obj).data("reverse");
            if (reverse != null) $(obj).mask(maskFormat, { reverse: reverse });
            else $(obj).mask(maskFormat);
          });
      }
      initTooltipPlugin() {
        $.fn.tooltip && $('[data-toggle="tooltip"]').tooltip();
      }
      initPopoverPlugin() {
        $.fn.popover && $('[data-toggle="popover"]').popover();
      }
      init() {
        var $this = this;
        this.initInputMask(), this.initTooltipPlugin(), this.initPopoverPlugin();
      }
    }
    ($.NumeroPlugins = new NumeroPlugins()), ($.NumeroPlugins.Constructor = NumeroPlugins);
  })(window.jQuery),
    (($) => {
      class NumeroApp {
        constructor() {
          (this.$body = $("body")), (this.$window = $(window));
        }
        initMenu() {
          var $this = this;
          // Left menu collapse
          $(".button-menu-mobile").on("click", (event) => {
            event.preventDefault();
            $this.$body.toggleClass("sidebar-enable");
            if ($this.$window.width() >= 1280) {
              $this.$body.toggleClass("enlarged");
            } else {
              $this.$body.removeClass("enlarged");
            }
          });
          // left side menu toggle
          $(".left-side-menu-toggle").on("click", () => {
            const isBodyEnlarged = this.$body.hasClass("enlarged");

            localStorage.setItem("isBodyEnlarged", JSON.stringify(!isBodyEnlarged));

            $this.$body.toggleClass("enlarged");
          });
          // sidebar - main menu
          $(".side-nav").metisMenu();
          // right side-bar toggle
          $(".right-bar-toggle").on("click", () => {
            $("body").toggleClass("right-bar-enabled");
          });
          $(document).on("click", "body", (event) => {
            if ($(event.target).closest(".right-bar-toggle, .right-bar").length > 0) {
              return;
            }
            if (
              $(event.target).closest(".left-side-menu, .side-nav").length > 0 ||
              $(event.target).hasClass("button-menu-mobile") ||
              $(event.target).closest(".button-menu-mobile").length > 0
            ) {
              return;
            }
            $("body").removeClass("right-bar-enabled");
            $("body").removeClass("sidebar-enable");
            return;
          });
          // Topbar - main menu
          $(".navbar-toggle").on("click", () => {
            $(this).toggleClass("open");
            $("#navigation").slideToggle(400);
          });
          $(".dropdown-menu a.dropdown-toggle").on("click", () => {
            if (!$(this).next().hasClass("show")) {
              $(this).parents(".dropdown-menu").first().find(".show").removeClass("show");
            }
            var $subMenu = $(this).next(".dropdown-menu");
            $subMenu.toggleClass("show");
            return false;
          });
        }
        initLayout() {
          const parsedIsBodyEnlarged =
            Boolean(JSON.parse(localStorage.getItem("isBodyEnlarged"))) ||
            this.$body.data("collapsed-sidebar") == true;

          if (parsedIsBodyEnlarged) {
            this.$body.addClass("enlarged");
          } else {
            // in case of small size, add class enlarge to have minimal menu
            if (this.$window.width() >= 768 && this.$window.width() <= 1280) {
              this.$body.addClass("enlarged");
            } else {
              if (this.$body.data("keep-enlarged") != true) {
                this.$body.removeClass("enlarged");
              }
            }
          }
        }
        init() {
          var $this = this;
          this.initLayout();
          this.initMenu();
          $.NumeroPlugins.init();
          // on window resize, make menu flipped automatically
          $this.$window.on("resize", (event) => {
            event.preventDefault();
            $this.initLayout();
          });
        }
      }
      ($.NumeroApp = new NumeroApp()), ($.NumeroApp.Constructor = NumeroApp);
    })(window.jQuery),
    (($) => {
      $.NumeroApp.init();
    })(window.jQuery);
});
